import {useState, useEffect} from 'react';

import { API, graphqlOperation } from "aws-amplify";

import {unionBy} from 'lodash-es';


import FormInput from '../components/FormInput';
import Table from '../components/Table';
import ConfirmModal from '../components/ConfirmModal';

const PAGE_LIMIT = 10;

const initial_state_state = {
  name: '',
  abbr: '',
  stateCountryId: ''
}





function States () {
  const [newState, setNewState] = useState(initial_state_state);
  const [states, setStates] = useState([]);
  const [nextToken, setNextToken] = useState<String | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const onChangeFormField = (key: string, val: any) => {
    setNewState({
      ...newState,
      [key]: val
    });
  }

  const createState = async () => {
    // try {
    //   const payload = await API.graphql(graphqlOperation(mutations.createState, {input: newState}));
    //   setNewState(initial_state_state);
    //   getStatesListPaginated();
    // } catch (e) {
    //   console.log(e);
    // }

  }

  const deleteState = async (stateId: any) => {
    // try {
    //   await API.graphql(graphqlOperation(mutations.deleteState, {input: {id: stateId}}));
    //   //notification
    // } catch (e) {
    //   console.log(e);
    // }
  }

  const getStatesListPaginated = async () => {
    try {
      if(!nextToken) {
          //@ts-ignore
        let payload = await API.graphql(graphqlOperation(queries.listStates, {limit: PAGE_LIMIT}));
        console.log(payload);
        //@ts-ignore
        let {data} = payload;
        setStates(data.listStates.items);
        setNextToken(data.listStates.nextToken);
      }
      else {
        //@ts-ignore
        let {data} = await API.graphql(graphqlOperation(queries.listStates, {nextToken: nextToken, limit: PAGE_LIMIT}));
        setStates(unionBy(states, data.listStates.items, 'id'));
        setNextToken(data.listStates.nextToken);
      }
    } catch (e)  {
      console.log(e);
    }
  }



  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      defaultCanSort: true
    },
    {
      Header: 'Abbr',
      accessor: 'abbr',
      defaultCanSort: true
    },
    {
      Header: 'Country',
      accessor: (item: any) => item.country != null ? item.country.name : 'n/a',
      defaultCanSort: false
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (item: any) => {
        return <button>delete</button>
      }
    }
  ]

  return (
    <div className="h-full w-full">
      <div className="p-8">
        <div className="flex flex-row h-full w-full">
          <div className="flex flex-col basis-3/4 py-20 px-10 border-r-2">
            
            <h1 className="self-start font-bold">
              States
            </h1>

            <p className="self-start py-4">
              --
            </p>
            <div className="row-auto self-start">
              <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4" 
              onClick={getStatesListPaginated}>load list</button>
              <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4" 
              onClick={() => setModalVisible(!modalVisible)}>modal</button>
            </div>

            <div className="border-2 py-2">
              <Table columns={columns} data={states} />
            </div>

          

            
            
          </div>
          <div className="flex flex-col basis-1/4 py-20 px-10">
            <div className="flex flex-row">
              <h3>
                add new state
              </h3>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newState.name} label="Name" onChangeVal={onChangeFormField} paramkey="name"/>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newState.abbr} label="Abbr" onChangeVal={onChangeFormField} paramkey="abbr"/>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newState.stateCountryId} label="Country ID" onChangeVal={onChangeFormField} paramkey="stateCountryId"/>
            </div>

            <div className="flex items-center py-4">
              <button className="shadow bg-blue-200 hover:bg-purple-400 focus:shadow-outline 
                focus:outline-none font-bold py-2 px-4 rounded" 
                onClick={createState}
                type="button">
                Add State
              </button>

            </div>
            
          </div>
        </div>
      </div>

      <ConfirmModal modalVisible={modalVisible} onOk={deleteState} onCancel={() => setModalVisible(false)}/>

    </div>
  );

}

export default States;