import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    currentUserName: '',
    users: [],
    
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setCurrentUserName: (state, action) => {
      state.currentUserName = action.payload;
    }
  }
});

export const {setCurrentUser, setCurrentUserName} = userSlice.actions;

export default userSlice.reducer;