import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import './App.css';
import {Auth, Amplify} from 'aws-amplify';
import awsExports from './aws-exports';
import Login from './main/containers/Login';
import SignUp from './main/containers/SignUp';
import Verify from './main/containers/Verify';
import Splash from './adminpanel/containers/Splash';
import AdminHome from './adminpanel/containers/AdminHome';
import Home from './main/containers/Home';
import TempLanding from './main/containers/TempLanding';
Amplify.configure(awsExports);

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log(user);
        if(user) {
          setLoggedIn(true);
        }
      } catch (e) {
        setLoggedIn(false);
      }
    }
    checkLoggedIn();
  }, []);
  console.log(isLoggedIn);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<TempLanding />}/>
          <Route path='/home' element={<Home />}/>
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp/>} />
          <Route path='/verify' element={<Verify/>} />
          <Route path='/admin/*' element={<AdminHome />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;
