import React from 'react'
import {Link} from 'react-router-dom'

function SidebarItem(props: any) {
  return (
    <div className={props.active ? "h-10 my-2 content-center rounded-md bg-indigo-500 text-white" : "h-10 my-2 content-center text-black"}>
      <div className="my-2">
        <Link to={props.to} onClick={(e) => props.onClick(e, props.to)}>
          {props.label}
        </Link>
      </div>
    </div>
  )
}

export default SidebarItem
