

function Dashboard () {
  return (
    <div className="flex h-full">
      <div className="m-auto">
        <h2>
          Dashboard Placeholder
        </h2>
      </div>
    </div>
  );
}

export default Dashboard;