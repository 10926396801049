

function Properties () {
  return (
    <div className="flex h-full">
      <div className="m-auto">
        <h2>
          Properties Placeholder
        </h2>
      </div>
    </div>
  );
}

export default Properties;