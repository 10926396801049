import {useState, useEffect} from 'react'

import { API, graphqlOperation } from "aws-amplify";

import {unionBy} from 'lodash-es';


import FormInput from '../components/FormInput';
import Table from '../components/Table';

const initial_country_state = {
  name: '',
  abbr: '',
  flag: ''
};


const columns = [
  {
    Header: 'ID',
    accessor: 'id',
    defaultCanSort: true
  },
  {
    Header: 'Name',
    accessor: 'name',
    defaultCanSort: true
  },
  {
    Header: 'Abbr',
    accessor: 'abbr',
    defaultCanSort: true
  },
  {
    Header: 'Flag',
    accessor: 'flag',
    defaultCanSort: true
  }
]

const PAGE_LIMIT = 10;

function Countries () {
  const [newCountry, setNewCountry] = useState(initial_country_state);
  const [countries, setCountries] = useState([]);
  const [nextToken, setNextToken] = useState<String | null>(null);


  const onChangeFormField = (key: string, val: any) => {
    setNewCountry({
      ...newCountry,
      [key]: val
    });
  }


  const createCountry = async () => {
    // try {
    //   const payload = await API.graphql(graphqlOperation(mutations.createCountry, {input: newCountry}));
    //   setNewCountry(initial_country_state);
    //   getCountriesListPaginated();
    // } catch (e) {
    //   console.log(e);
    // }

  }

  const getCountriesListPaginated = async () => {
    try {
      if(!nextToken) {
          //@ts-ignore
        let payload = await API.graphql(graphqlOperation(queries.listCountries, {limit: PAGE_LIMIT}));
        console.log(payload);
        //@ts-ignore
        let {data} = payload;
        setCountries(data.listCountries.items);
        setNextToken(data.listCountries.nextToken);
      }
      else {
        //@ts-ignore
        let {data} = await API.graphql(graphqlOperation(queries.listCountries, {nextToken: nextToken, limit: PAGE_LIMIT}));
        setCountries(unionBy(countries, data.listCountries.items, 'id'));
        setNextToken(data.listCountries.nextToken);
      }
    } catch (e)  {
      console.log(e);
    }
  }
  return (
    <div className="h-full w-full">
      <div className="p-8">
        <div className="flex flex-row h-full w-full">
          <div className="flex flex-col basis-3/4 py-20 px-10 border-r-2">
            
            <h1 className="self-start font-bold">
              Countries
            </h1>

            <p className="self-start py-4">
              --
            </p>
            <div className="row-auto self-start">
              <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4" 
              onClick={getCountriesListPaginated}>load list</button>
            </div>

            <div className="border-2 py-2">
              <Table columns={columns} data={countries} />
            </div>

          

            
            
          </div>
          <div className="flex flex-col basis-1/4 py-20 px-10">
            <div className="flex flex-row">
              <h3>
                add new country
              </h3>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newCountry.name} label="Name" onChangeVal={onChangeFormField} paramkey="name"/>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newCountry.abbr} label="Abbr" onChangeVal={onChangeFormField} paramkey="abbr"/>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newCountry.flag} label="Flag" onChangeVal={onChangeFormField} paramkey="flag"/>
            </div>

            <div className="flex items-center py-4">
              <button className="shadow bg-blue-200 hover:bg-purple-400 focus:shadow-outline 
                focus:outline-none font-bold py-2 px-4 rounded" 
                onClick={createCountry}
                type="button">
                Add Country
              </button>

            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Countries;