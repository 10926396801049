

function FormInput(props: any) {
  let {onChangeVal, value, paramkey, label} = props;

  const handleChange = (val: any) => {
    onChangeVal(paramkey, val);
  }

  return (
    <>
      <div className="md:w-1/3">
        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-label">
          {label}
        </label>
      </div>
      <div className="md:w-2/3">
        <input className="bg-gray-200 appearance-none border-2 border-gray-200 
          rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none 
          focus:bg-white focus:border-purple-500" 
          id="inline-label" type="text" value={value}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          />
      </div>
    </>
  );
}

export default FormInput;