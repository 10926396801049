import { Auth, API, graphqlOperation } from 'aws-amplify'
// import * as queries from '../graphql/queries'
// import * as mutations from '../graphql/mutations'
import userEvent from '@testing-library/user-event'




export const signIn = (username: string, password: string) => {
	return new Promise((resolve, reject) => {
		Auth.signIn({
		    username, // Required, the username
		    password, // Optional, the password
		    //validationData, // Optional, a random key-value pair map which can contain any key and will be passed to your PreAuthentication Lambda trigger as-is. It can be used to implement additional validations around authentication
		}).then(user => resolve(user))
		.catch(err => reject(err));
	})
}

export const signUp = (username: string, password: string, given_name: string, family_name: string) => {
	//create cognito
	return new Promise((resolve, reject) => {
		Auth.signUp({
			username, password, attributes:{given_name, family_name}
		}).then(user => resolve(user)).catch(err => reject(err));
	})
}

export const createDBUser = async (cognitoUser: any) => {
	//create db user
	let userObj = {
		first_name: cognitoUser.attributes.given_name,
		last_name: cognitoUser.attributes.family_name,
		cognito_id: cognitoUser.attributes.sub,
		email: cognitoUser.attributes.email,
		status: 'ACTIVE',
		online_status: 'ONLINE'
	};
	//let newUser = await API.graphql(graphqlOperation(mutations.createUser, {input: userObj}));
	//console.log(newUser);
	//update cognito with db id
	//@ts-ignore
	await Auth.updateUserAttributes(cognitoUser, {'custom:db_id': newUser.data.createUser.id});
}

export const confirmSignUp = (username: string, code: string) => {
	return new Promise((resolve, reject) => {
		Auth.confirmSignUp(username, code, {
			// Optional. Force user confirmation irrespective of existing alias. By default set to True.
			forceAliasCreation: true
		}).then(data => resolve(data))
			.catch(err => reject(err));
	})
}

//could also confirm sign in later

export const resendSignUp = (username: string) => {
	return new Promise((resolve, reject) => {
		Auth.resendSignUp(username).then(() => {
			console.log('code resent successfully');
			resolve(true);
		}).catch(e => {
			console.log(e);
			reject(e);
		});
	})
	
}