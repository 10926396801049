import React, {useState, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {signUp} from '../../api/amp-auth';
import {useNavigate} from 'react-router-dom';

import { useDispatch } from 'react-redux'
import { setCurrentUserName } from '../../redux/user';


function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errmsg, setErrMsg] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSignup = async(e: any) => {
    e.preventDefault();

    setErrMsg('');
    if(!isLoading) {
      
      setLoading(true);
      try {
        if(inputValidate()) {
          let user = await signUp(username, password, firstName, lastName);
          //if signup success, proceed to account verification
          console.log(user);
          dispatch(setCurrentUserName(username));
          navigate('/verify');
        }
        setLoading(false);
      }catch (e) {
        setLoading(false);
        setErrMsg('Error signing up. Please try again.');
      }
    }
  }

  const inputValidate = () => {
    if(password.length < 8 || passwordConfirm.length < 8) {
      setErrMsg('Password should be at least 8 characters.');
      return false;
    }
    if(password !== passwordConfirm) {
      setErrMsg('Passwords do not match.');
      return false;
    }
    if(firstName === '' || lastName === '') {
      setErrMsg('Name fields must not be empty.');
      return false;
    }
    return true;
    
  }

  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <h1>
        OurHood Signup
        </h1>
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Email
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="username" 
              type="text" 
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}

            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              First Name
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="firstName" 
              type="text" 
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}

            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Last Name
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="lastName" 
              type="text" 
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" 
              id="password" 
              type="password" 
              placeholder="******************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="passwordConfirm">
              Confirm Password
            </label>
            <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" 
              id="passwordConfirm" 
              type="password" 
              placeholder="******************"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <p className="text-red-500 text-xs italic">{errmsg}</p>
          </div>

          <div className="flex items-center justify-center">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
              type="button"
              onClick={handleSignup}
              disabled={isLoading}
              >
              {isLoading ? 'Loading...' : 'Sign up'}
            </button>
          </div>

        </form>
      </div>
    </div>
  );

}

export default SignUp;