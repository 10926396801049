import {useEffect, useState} from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify'

function Home () {
  const [cognitoUser, setCognitoUser] = useState<any>(null);

  useEffect(() => {
    const getUser = async () => {
      let user = await Auth.currentAuthenticatedUser();
      if(!!user && user.attributes.email_verified) {
        setCognitoUser(user);
      }
    }
    getUser();
  }, []);


  const styles = {
    welcomeToOurhood: {
      clipPath: "polygon(29% 30%, 72% 30%, 85% 0, 100% 0, 100% 100%, 0 100%, 0 11%, 13% 11%)",
    },
    polygonDivParent: {
      filter: "url(#round)"
    }
  }

  const toggleMenu = () => {
    let menu = document.getElementById('header-menu');
    menu?.classList.toggle('hidden');
    menu?.classList.toggle('w-full');
    menu?.classList.toggle('h-screen');
  }

  const userAcknowledge = () => {
    if(cognitoUser != null) {
      return `Welcome, ${cognitoUser.attributes.given_name}`;
    } else {
      return 'Get started (login logo here)';
    }
  }

  return (
    <div className="flex-col w-full">

      {/* header */}
      <div id="home-header" className="w-full h-16">
        <div className="container px-4 md:px-0 h-full mx-auto flex justify-between items-center">
          <div id="logo">
            LOGO
          </div>

          <ul id="header-menu" className="hidden fixed top-0 right-0 px-10 py-16 bg-gray-200 z-50
                md:relative md:flex md:p-0 md:bg-transparent md:flex-row md:space-x-6">
            <li className="md:hidden z-90 fixed top-4 right-6">
              <a href="#" className="text-right text-black text-4xl"
                        onClick={toggleMenu}>&times;</a>    
            </li>
            <li>
              <a className="text-black opacity-70 hover:opacity-100 duration-300" href="#">Discover</a>
            </li>
            <li>
              <a className="text-black opacity-70 hover:opacity-100 duration-300" href="#">Experts</a>
            </li>
            <li>
              <a className="text-black opacity-70 hover:opacity-100 duration-300" href="#">Guides</a>
            </li>
            <li>
              <a className="text-black opacity-70 hover:opacity-100 duration-300" href="#">Tools</a>
            </li>
            <li>
              <a className="text-black opacity-70 hover:opacity-100 duration-300" href="#">Resources</a>
            </li>
            <li>
              <a className="text-black opacity-70 hover:opacity-100 duration-300" href="#">{userAcknowledge()}</a>
            </li>
          </ul>

          <div className="flex items-center md:hidden">
            <button className="text-black text-4xl font-bold opacity-70 hover:opacity-100 duration-300"
              onClick={toggleMenu}>
              &#9776;
            </button>
          </div>
        </div>
      </div>

      {/* hero text */}
      <div className="px-10 py-16">
        <div className="pt-10 md:text-left md:pt-10 md:pl-10 text-5xl">
          Property isn't simple, but we make it easy
        </div>
        <div className="pt-5 md:text-left md:pt-10 md:pl-10">
          Helping you make informed decisions throughout your property journey
        </div>
        <div className="pt-5 md:text-left md:pt-10 md:pl-10 flex-row">
          <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4">
            Buying
          </button>
          <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4">
            Selling
          </button>
        </div>
      </div>

      {/* second row, polygonal div*/}
      <div className="relative" style={styles.polygonDivParent}>
        <div className="absolute top-0 right-20 z-50">
          oh image
        </div>
        <div className="bg-slate-700 h-max flex-col justify-center block" style={styles.welcomeToOurhood}>
          <div className="pt-60 md:pt-40 px-20 text-white text-5xl">
            Welcome to<br/>Ourhood
          </div>
          <div className="py-10 text-white w-1/2 m-auto">
            OurHood is your neighbourhood - a place to connect with nearby professionals. It's a trusted place where you make the right connections with people you trust.
          </div>
          <div>
            <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4">
              Professionals near you
            </button>
          </div>
        </div>
      </div>

      {/*third row, complicated roadmap polygonal div */}

      <div className="flex-col">
        <div className="flex flex-row justify-around p-10">
          <div className="flex-col">
            <div>
              Begin planning your property journey
            </div>
            <div>
              yadda yadda yadda
            </div>
            <div>
              <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4">
                Guide me
              </button>
            </div>
          </div>
          <div>
            image 1
          </div>
        </div>
        <div className="flex flex-row justify-around p-10">
          <div>
            image 1
          </div>
          <div className="flex-col">
            <div>
              Begin planning your property journey
            </div>
            <div>
              yadda yadda yadda
            </div>
            <div>
              <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4">
                Guide me
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-around p-10">
          <div className="flex-col">
            <div>
              Begin planning your property journey
            </div>
            <div>
              yadda yadda yadda
            </div>
            <div>
              <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4">
                Guide me
              </button>
            </div>
          </div>
          <div>
            image 1
          </div>
        </div>
      </div>

      {/*fourth row, articles */}

      <div className="mx-6 py-2 bg-gray-200 rounded-xl">
        <div className="py-4 text-5xl">
          Neighb'OurHood' Watch
        </div>
        <div className="py-4 w-1/2 m-auto">
          Join the community and hear our latest stories and new with tips to help you along hte way
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-auto flex-col">
            <div>
              image
            </div>
            <div>
              Article
            </div>
            <div>
              Make moving as smooth as possible
            </div>
            <div>
              Read more
            </div>
          </div>
          <div className="flex-auto flex-col">
            <div>
              image
            </div>
            <div>
              Article
            </div>
            <div>
              Make moving as smooth as possible
            </div>
            <div>
              Read more
            </div>
          </div>
          <div className="flex-auto flex-col">
            <div>
              image
            </div>
            <div>
              Article
            </div>
            <div>
              Make moving as smooth as possible
            </div>
            <div>
              Read more
            </div>
          </div>
        </div>
      </div>

      {/*svg filter for rounded polygons*/}
      <svg className="hidden absolute" width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="round">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 19 -9" result="goo" />
            <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
          </filter>
        </defs>
      </svg>

    </div>
  )
}



export default Home;