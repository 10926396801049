import React, {useState, useEffect} from 'react';
import { Auth } from 'aws-amplify';
import {createDBUser, signIn} from '../../api/amp-auth';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux'
import { setCurrentUserName } from '../../redux/user';

function Login () {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errmsg, setErrMsg] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [mounted, setMount] = useState(false);

  const handleLogin = async (e: any) => {
    
    e.preventDefault();
    
    setErrMsg('');
    if(!isLoading) {
      setLoading(true);
      try {
        let user = await signIn(username, password);
        user = await Auth.currentAuthenticatedUser();
        console.log(user);
        setUsername('');
        setPassword('');
        //@ts-ignore
        console.log(user.attributes['custom:db_id']);
        //@ts-ignore
        console.log(user.attributes.email_verified);
        //if user is not confirmed, navigate to confirmation page
        //@ts-ignore
        if(!user.attributes.email_verified) {
          navigate('/verify');
          //@ts-ignore
        } else if(typeof user.attributes['custom:db_id'] === 'undefined' || user.attributes['custom:db_id'] == null){
          console.log('else if');
           //if user is confirmed and has no db entry yet, create it here
           let data = await createDBUser(user);
        }
        //window.location.href = '/';
        setLoading(false);
        navigate('/');
      } catch (e: any) {
        console.log(e);
        setLoading(false);
        if (e.code === "UserNotConfirmedException") {
          dispatch(setCurrentUserName(username));
          navigate('/verify');
        } else {
          setErrMsg('Invalid username and/or password');
        }
        
      }
    }
  }

  const logout = async () => {
    try {
      await Auth.signOut();
      console.log("sign out success");
    } catch (err) {
      console.log('error signing out...: ', err);
    }
  }

  useEffect(() => {
    if(!mounted) logout()
    setMount(true)
  });

  return (

    <div className="flex h-screen">
      <div className="m-auto">
        <h1>
        OurHood Login
        </h1>
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Username
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="username" 
              type="text" 
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}

            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" 
              id="password" 
              type="password" 
              placeholder="******************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <p className="text-red-500 text-xs italic">{errmsg}</p>
          </div>

          <div className="flex items-center justify-center">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
              type="button"
              onClick={handleLogin}
              disabled={isLoading}
              >
              {isLoading ? 'Logging you in...' : 'Sign in'}
            </button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default Login;
