

function Calculators () {
  return (
    <div className="flex h-full">
      <div className="m-auto">
        <h2>
          Calculators Placeholder
        </h2>
      </div>
    </div>
  );
}

export default Calculators;