import {useState, useEffect} from 'react'

import { API, graphqlOperation } from "aws-amplify";
import {useInterval} from '../../custom_hooks';

import {unionBy} from 'lodash-es';

import Table from '../components/Table';
import FormInput from '../components/FormInput';



const initial_parameter_state = {
  label: '',
  unit: ''
};

const columns = [
  {
    Header: 'ID',
    accessor: 'id',
    defaultCanSort: true
  }, 
  {
    Header: 'Label',
    accessor: 'label',
    defaultCanSort: true
  }, 
  {
    Header: 'Unit',
    accessor: 'unit',
    defaultCanSort: true
  }
];

const POLL_TIMEOUT = 360000;
const PAGE_LIMIT = 10;

function Parameters () {
  const [newParameter, setNewParameter] = useState(initial_parameter_state);
  const [parameters, setParameters] = useState([]);
  const [nextToken, setNextToken] = useState<String | null>(null);
  const [force_poll, setForcePoll] = useState(true);
  const [loading, setLoading] = useState(false);

  const onChangeFormField = (key: string, val: any) => {
    setNewParameter({
      ...newParameter,
      [key]: val
    });
  }

  const createParam = async () => {
    // try {
    //   const newParam = await API.graphql(graphqlOperation(mutations.createParameter, {input: newParameter, 
    //     //authMode: 'AMAZON_COGNITO_USER_POOLS'
    //   }));
    //   console.log(newParam);
    //   //notySuccess('Parameter created').show();
    //   setNewParameter(initial_parameter_state);
    //   //temporary
    //   getParamsListPaginated();
    // } catch (e: any) {
    //   console.log(e);
    // }
  }

  const getParamsListPaginated = async () => {
    // try {
    //   if(!nextToken) {
    //       //@ts-ignore
    //     let payload = await API.graphql(graphqlOperation(queries.listParameters, {limit: PAGE_LIMIT}));
    //     console.log(payload);
    //     //@ts-ignore
    //     let {data} = payload;
    //     setParameters(data.listParameters.items);
    //     setNextToken(data.listParameters.nextToken);
    //   }
    //   else {
    //     //@ts-ignore
    //     let {data} = await API.graphql(graphqlOperation(queries.listParameters, {nextToken: nextToken, limit: PAGE_LIMIT}));
    //     setParameters(unionBy(parameters, data.listParameters.items, 'id'));
    //     setNextToken(data.listParameters.nextToken);
    //   }
    // } catch (e)  {
    //   console.log(e);
    // }
  }

  // useInterval(async() => {
  //   if(force_poll) setLoading(true);
  //   setForcePoll(false);
  //   //@ts-ignore
  //   let {data} = await API.graphql(graphqlOperation(queries.listParameters));
  //   let temp_params = data.listParameters.items;
  //   console.log(data.listParameters.items);
  //   setParameters(data.listParameters.items);
  //   setLoading(false);
  //   //pagination iterate
  //   while(data.listParameters.nextToken) {
  //     data = await API.graphql(graphqlOperation(queries.listParameters, {nextToken: data.listParameters.nextToken}));
  //     data = data.data;
  //     temp_params = unionBy(temp_params, data.listParameters.items, 'id');
  //     setParameters(temp_params);
  //   }
  // }, force_poll ? 100 : POLL_TIMEOUT);



  return (
    <div className="h-full w-full">
      <div className="p-8">
        <div className="flex flex-row h-full w-full">
          <div className="flex flex-col basis-3/4 py-20 px-10 border-r-2">
            
            <h1 className="self-start font-bold">
              Parameters
            </h1>

            <p className="self-start py-4">
              Define parameters that would be used for suburb data.
            </p>
            <div className="row-auto self-start">
              <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4" 
              onClick={getParamsListPaginated}>load list</button>
            </div>

            <div className="border-2 py-2">
              <Table columns={columns} data={parameters} />
            </div>

          

          {/* <nav aria-label="pagination">
            <ul className="inline-flex -space-x-px my-6">
              <li>
                <a href="#" className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</a>
              </li>
              <li>
                <a href="#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">1</a>
              </li>
              <li>
                <a href="#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">2</a>
              </li>
              <li>
                <a href="#" aria-current="page" className="px-3 py-2 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">3</a>
              </li>
              <li>
                <a href="#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">4</a>
              </li>
              <li>
                <a href="#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">5</a>
              </li>
              <li>
                <a href="#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
              </li>
            </ul>
          </nav> */}

            
            
          </div>
          <div className="flex flex-col basis-1/4 py-20 px-10">
            <div className="flex flex-row">
              <h3>
                add new parameter
              </h3>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newParameter.label} label="Label" onChangeVal={onChangeFormField} paramkey="label"/>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newParameter.unit} label="Unit" onChangeVal={onChangeFormField} paramkey="unit"/>
            </div>

            <div className="flex items-center py-4">
              <button className="shadow bg-blue-200 hover:bg-purple-400 focus:shadow-outline 
                focus:outline-none font-bold py-2 px-4 rounded" 
                onClick={createParam}
                type="button">
                Add param
              </button>

            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Parameters;