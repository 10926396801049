import {useState, useEffect} from 'react';
import { API, graphqlOperation } from "aws-amplify";

import FormInput from '../components/FormInput';
import Table from '../components/Table';
import ConfirmModal from '../components/ConfirmModal';
import {unionBy} from 'lodash-es';

const PAGE_LIMIT = 10;

function Users () {

  const [users, setUsers] = useState([]);
  const [nextToken, setNextToken] = useState<String | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [userIdForDeletion, setUserIdForDeletion] = useState('');

  const getUserListPaginated = async () => {
    try {
      if(!nextToken) {
          //@ts-ignore
        let payload = await API.graphql(graphqlOperation(queries.listUsers, {limit: PAGE_LIMIT}));
        console.log(payload);
        //@ts-ignore
        let {data} = payload;
        setUsers(data.listUsers.items);
        setNextToken(data.listUsers.nextToken);
      }
      else {
        //@ts-ignore
        let {data} = await API.graphql(graphqlOperation(queries.listUsers, {nextToken: nextToken, limit: PAGE_LIMIT}));
        setUsers(unionBy(users, data.listUsers.items, 'id'));
        setNextToken(data.listUsers.nextToken);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const deleteUser = async (userId: any) => {
    // try {
    //   await API.graphql(graphqlOperation(mutations.deleteUser, {input: {id: userId}}));
    //   setModalVisible(false);
    //   setUserIdForDeletion('');
    //   setNextToken(null);
    //   getUserListPaginated();
    // } catch (e) {
    //   console.log(e);
    // }
  }

  const confirmDeleteUser = async (userId: any) => {
    console.log(userId);
    setModalVisible(true);
    setUserIdForDeletion(userId);
  }

  const columns = [
    {
      Header: 'First Name',
      accessor: 'first_name',
      defaultCanSort: true
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
      defaultCanSort: true
    },
    {
      Header: 'Email',
      accessor: 'email',
      defaultCanSort: false
    },
    {
      Header: 'Cognito id',
      accessor: 'cognito_id',
      defaultCanSort: false
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (item: any) => {
        return <button onClick={() => confirmDeleteUser(item.value)} >delete</button>
      }
    }
  ]

  return (
    <div className="h-full w-full">
    <div className="p-8">
      <div className="flex flex-row h-full w-full">
        <div className="flex flex-col basis-3/4 py-20 px-10 border-r-2">
          
          <h1 className="self-start font-bold">
            Users
          </h1>

          <p className="self-start py-4">
            --
          </p>
          <div className="row-auto self-start">
            <button className="shadow font-bold py-2 px-4 rounded bg-blue-200 w-40 my-2 mr-4" 
            onClick={getUserListPaginated}>load list</button>
          </div>

          <div className="border-2 py-2">
            <Table columns={columns} data={users} />
          </div>
        </div>

          {/* <div className="flex flex-col basis-1/4 py-20 px-10">
            <div className="flex flex-row">
              <h3>
                add new user
              </h3>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newState.name} label="Name" onChangeVal={onChangeFormField} paramkey="name"/>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newState.abbr} label="Abbr" onChangeVal={onChangeFormField} paramkey="abbr"/>
            </div>
            <div className="flex items-center py-4">
              <FormInput value={newState.stateCountryId} label="Country ID" onChangeVal={onChangeFormField} paramkey="stateCountryId"/>
            </div>

            <div className="flex items-center py-4">
              <button className="shadow bg-blue-200 hover:bg-purple-400 focus:shadow-outline 
                focus:outline-none font-bold py-2 px-4 rounded" 
                onClick={createState}
                type="button">
                Add User
              </button>

            </div>
            
          </div> */}
      </div>
    </div>

    <ConfirmModal modalVisible={modalVisible} onOk={() => deleteUser(userIdForDeletion)} onCancel={() => setModalVisible(false)}/>

  </div>
  );
}

export default Users;