import {useState, useEffect} from 'react';
import SidebarItem from '../components/SidebarItem';
import {Route, Routes} from 'react-router-dom';
import {Auth, Amplify} from 'aws-amplify';
import Users from './Users';
import Suburbs from './Suburbs';
import Properties from './Properties';
import Calculators from './Calculators';
import Dashboard from './Dashboard';
import Parameters from './Parameters';
import States from './States';
import Countries from './Countries';
import oh_logo from '../../static/oh-logo.png';

interface MenuItem {
  path: string,
  label: string
}

const menu_items_array: Array<MenuItem> = [
  {
    path: '/admin/dashboard',
    label: 'Dashboard',
  },
  {
    path: '/admin/users',
    label: 'Users'
  },
  {
    path: '/admin/suburbs',
    label: 'Suburbs'
  },
  {
    path: '/admin/properties',
    label: 'Properties'
  },
  {
    path: '/admin/calculators',
    label: 'Calculators'
  },
  {
    path: '/admin/parameters',
    label: 'Parameters'
  },
  {
    path: '/admin/states',
    label: 'States'
  },
  {
    path: '/admin/countries',
    label: 'Countries'
  },
  {
    path: '/login',
    label: 'Logout'
  },
]

function AdminHome () {
  const [current_path, setCurrentPath] = useState(window.location.pathname);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setAdmin] = useState(false);

  const clickItem = (e: any, to: string) => {
    setCurrentPath(to);
  }

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if(user) {
          console.log(user);
          setLoggedIn(true);
          if(user.signInUserSession.accessToken.payload['cognito:groups'].includes('Admin')) {
            setAdmin(true);
          }
        }
      } catch (e) {
        setLoggedIn(false);
      }
    }
    checkLoggedIn();
  }, []);  

  //return nothing, don't display anything to the user if they're not an admin
  if(!loggedIn || !isAdmin) return (<></>);

  return (
    <div className="h-screen w-full">
      <div className="flex flex-row h-full">
        <div className="flex flex-col basis-1/5 border-r-2 justify-start content-center py-20 px-10">
          <img src={oh_logo} alt="oh logo" width="50%" className="self-center mb-10" />
          {menu_items_array.map((m, i) => 
            <SidebarItem key={i} label={m.label} onClick={clickItem} to={m.path} active={current_path === m.path}/>
          )}
        </div>

        <div className="flex flex-col basis-4/5">
          <Routes>
            <Route path="dashboard" element={<Dashboard />}/>
            <Route path="users" element={<Users />}/>
            <Route path="suburbs" element={<Suburbs />}/>
            <Route path="properties" element={<Properties />}/>
            <Route path="calculators" element={<Calculators />}/>
            <Route path="parameters" element={<Parameters />}/>
            <Route path="states" element={<States />}/>
            <Route path="countries" element={<Countries />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default AdminHome;