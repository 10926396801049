import oh_logo from '../../static/oh-logo.png';

function TempLanding () {
  return (
    <div className="flex h-screen w-screen">
      <div className="m-auto w-1/2 justify-center align-middle content-center">
        <img src={oh_logo} alt="oh logo" className="self-center m-auto w-1/3" />
        <div className="my-5">
          Please standby. We're brewing something new...
        </div>
      </div>
    </div>
  )


}

export default TempLanding;